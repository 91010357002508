import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { TypedCanActivateFn } from '@/common/models';
import { AuthService } from '@/pages/auth/services/auth.service';

export const CompanyGuard: TypedCanActivateFn<boolean | UrlTree> = (): boolean | UrlTree => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.getUser()?.entity.id === null ? true : router.createUrlTree(['/errors/not_found']);
};
